<template>
    <div style="height: calc(100vh - 300px); width: 100%;" v-loading.fullscreen.lock="loading">

    </div>
</template>
<script>
    export default{
        data() {
            return {
                loading: true
            }
        },
        created(){
            const ruta = process.env.VUE_APP_URL_FULL_SMART;
            window.open(`${ruta}/proyectos/proceso/mosaico`, "_self")
        },
    }
</script>